export const navbarItems = [
  {
    id: 1,
    title: "home",
    link: "home",
  },

  {
    id: 2,
    title: "products",
    link: "products",
  },

  {
    id: 3,
    title: "ourCompany",
    link: "ourCompany",
  },

  {
    id: 4,
    title: "services",
    link: "services",
  },

  {
    id: 5,
    title: "contactUs",
    link: "contactUs",
  },
];
